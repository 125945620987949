import { colors, createTheme } from '@mui/material'

const theme = createTheme({
    palette: {
        primary: {
            main: '#4694d1',
        },
        secondary: {
            main: '#c9caca',
        },
        error: {
            main: colors.red.A400,
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            // ボタンのラベルが大文字になるのを防ぐ
            textTransform: 'none',
        },
    },
    components: {
        MuiFormControl: {
            defaultProps: {
                margin: 'normal',
                variant: 'standard',
            },
        },
        MuiFormLabel: {
            defaultProps: {
                sx: {
                    // 上位部分の余白を空けて、 FormLabel と その上部のコンポーネントの違いをわかりやすくする。
                    pt: 1,
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
                ::-webkit-scrollbar {
                    width: 16px;
                    height: 16px;
                },
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(255, 255, 255, 1.0);
                    background-clip: padding-box;
                    border: 4px solid transparent;
                    border-radius: 8px;
                },
                ::-webkit-scrollbar-track {
                    margin: 4px;
                },
                ::-webkit-scrollbar-corner {
                    display: none;
                },
            `
        }
    },
})

export default theme
