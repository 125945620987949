import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ViewMode =
    | "VIEW_MODE_BROWSE"
    | "VIEW_MODE_EDIT"

export type WaterGateActionType = "view" | "change" | "add" | "delete"

export interface UserFieldPolygonModificationProperty {
    running: boolean,
    userFieldId: string,
    initialLatLngs: LatLng[],
}

export interface LatLng {
    lat: number
    lng: number
}

export interface SelectedState {
    currentMasterFieldKey: string | undefined
    selectedCompanyId: string
    selectedCompanyName?: string
    selectedUserFieldGroupId?: string
    selectedUserFieldId?: string
    selectedWaterGateActionType?: WaterGateActionType
    selectedPictureAnalysisType: string | undefined
    viewMode: ViewMode
    isShowSoilMap: boolean
    userFieldPolygonModificationProperty: UserFieldPolygonModificationProperty
    selectedUserFieldIdsInUserFieldList: string[]
    selectedWaterGateIdsInWaterGateList: string[]
    userFieldGroupIdList: string[]
    filteredUserFieldGroupIdList: string[]
    userFieldIdList: string[]
    filteredUserFieldIdList: string[]
    wideOrthoimgUserFieldIds: string[]
}

export const defaultSelectedState: SelectedState = {
    currentMasterFieldKey: undefined,
    selectedCompanyId: "",
    selectedCompanyName: undefined,
    selectedUserFieldGroupId: undefined,
    selectedUserFieldId: undefined,
    selectedWaterGateActionType: "view",
    selectedPictureAnalysisType: undefined,
    viewMode: "VIEW_MODE_BROWSE",
    isShowSoilMap: false,
    userFieldPolygonModificationProperty: {
        running: false,
        userFieldId: "",
        initialLatLngs: [],
    },
    selectedUserFieldIdsInUserFieldList: [],
    selectedWaterGateIdsInWaterGateList: [],
    userFieldGroupIdList: [],
    filteredUserFieldGroupIdList: [],
    userFieldIdList: [],
    filteredUserFieldIdList: [],
    wideOrthoimgUserFieldIds: [],
}

const selectedStateSlice = createSlice({
    name: "selected-state",
    initialState: defaultSelectedState,
    reducers: {
        initializeSelectedStateForCompany: (state: SelectedState) => {
            state.currentMasterFieldKey = undefined
            state.selectedCompanyId = ""
            state.selectedCompanyName = undefined
            state.selectedUserFieldGroupId = undefined
            state.selectedUserFieldId = undefined
            state.selectedWaterGateActionType = "view"
            state.selectedPictureAnalysisType = undefined
            state.userFieldPolygonModificationProperty = defaultSelectedState.userFieldPolygonModificationProperty
            state.selectedUserFieldIdsInUserFieldList = []
            state.selectedWaterGateIdsInWaterGateList = defaultSelectedState.selectedWaterGateIdsInWaterGateList
            state.userFieldIdList = []
            state.filteredUserFieldIdList = []
            state.wideOrthoimgUserFieldIds = defaultSelectedState.wideOrthoimgUserFieldIds
        },
        initializeSelectedStateForUserFieldGroup: (state: SelectedState) => {
            state.currentMasterFieldKey = undefined
            state.selectedUserFieldGroupId = undefined
            state.selectedUserFieldId = undefined
            state.selectedPictureAnalysisType = undefined
            state.userFieldPolygonModificationProperty = defaultSelectedState.userFieldPolygonModificationProperty
            state.selectedUserFieldIdsInUserFieldList = []
            state.selectedWaterGateIdsInWaterGateList = defaultSelectedState.selectedWaterGateIdsInWaterGateList
            state.userFieldIdList = []
            state.filteredUserFieldIdList = []
        },
        initializeSelectedStateForUserField: (state: SelectedState) => {
            state.currentMasterFieldKey = undefined
            state.selectedUserFieldId = undefined
            state.selectedWaterGateActionType = "view"
            state.selectedPictureAnalysisType = undefined
            state.userFieldPolygonModificationProperty = defaultSelectedState.userFieldPolygonModificationProperty
            state.selectedUserFieldIdsInUserFieldList = []
            state.selectedWaterGateIdsInWaterGateList = defaultSelectedState.selectedWaterGateIdsInWaterGateList
        },
        initializeSelectedStateForViewMode: (state: SelectedState) => {
            state.currentMasterFieldKey = undefined
            state.selectedWaterGateActionType = "view"
            state.selectedPictureAnalysisType = undefined
            state.userFieldPolygonModificationProperty = defaultSelectedState.userFieldPolygonModificationProperty
            state.selectedUserFieldIdsInUserFieldList = []
            state.selectedWaterGateIdsInWaterGateList = defaultSelectedState.selectedWaterGateIdsInWaterGateList
            state.wideOrthoimgUserFieldIds = defaultSelectedState.wideOrthoimgUserFieldIds
        },
        setCurrentMasterFieldKey: (state: SelectedState, action: PayloadAction<string | undefined>) => {
            state.currentMasterFieldKey = action.payload
        },
        setSelectedCompanyId: (state: SelectedState, action: PayloadAction<string>) => {
            state.selectedCompanyId = action.payload
        },
        setSelectedCompanyName: (state: SelectedState, action: PayloadAction<string>) => {
            state.selectedCompanyName = action.payload
        },
        setSelectedUserFieldGroupId: (state: SelectedState, action: PayloadAction<string | undefined>) => {
            state.selectedUserFieldGroupId = action.payload
        },
        setSelectedUserFieldId: (state: SelectedState, action: PayloadAction<string | undefined>) => {
            state.selectedUserFieldId = action.payload
        },
        setSelectedWaterGateActionType: (state: SelectedState, action: PayloadAction<WaterGateActionType>) => {
            state.selectedWaterGateActionType = action.payload
        },
        setSelectedPictureAnalysisType: (state: SelectedState, action: PayloadAction<string | undefined>) => {
            state.selectedPictureAnalysisType = action.payload
        },
        setViewMode: (state: SelectedState, action: PayloadAction<ViewMode>) => {
            state.viewMode = action.payload
        },
        setIsShowSoilMap: (state: SelectedState, action: PayloadAction<boolean>) => {
            state.isShowSoilMap = action.payload
        },
        setUserFieldPolygonModificationProperty: (state: SelectedState, action: PayloadAction<UserFieldPolygonModificationProperty>) => {
            state.userFieldPolygonModificationProperty = action.payload
        },
        setSelectedUserFieldIdsInUserFieldList: (state: SelectedState, action: PayloadAction<string[]>) => {
            state.selectedUserFieldIdsInUserFieldList = action.payload
        },
        setSelectedWaterGateIdsInWaterGateList: (state: SelectedState, action: PayloadAction<string[]>) => {
            state.selectedWaterGateIdsInWaterGateList = action.payload
        },
        setUserFieldGroupIdList: (state: SelectedState, action: PayloadAction<string[]>) => {
            state.userFieldGroupIdList = action.payload
        },
        setFilteredUserFieldGroupIdList: (state: SelectedState, action: PayloadAction<string[]>) => {
            state.filteredUserFieldGroupIdList = action.payload
        },
        setUserFieldIdList: (state: SelectedState, action: PayloadAction<string[]>) => {
            state.userFieldIdList = action.payload
        },
        setFilteredUserFieldIdList: (state: SelectedState, action: PayloadAction<string[]>) => {
            state.filteredUserFieldIdList = action.payload
        },
        setWideOrthoimgUserFieldIds: (state: SelectedState, action: PayloadAction<string[]>) => {
            state.wideOrthoimgUserFieldIds = action.payload
        },
    }
})

export const {
    initializeSelectedStateForCompany,
    initializeSelectedStateForUserFieldGroup,
    initializeSelectedStateForUserField,
    initializeSelectedStateForViewMode,
    setCurrentMasterFieldKey,
    setSelectedCompanyId,
    setSelectedCompanyName,
    setSelectedUserFieldGroupId,
    setSelectedUserFieldId,
    setSelectedWaterGateActionType,
    setSelectedPictureAnalysisType,
    setViewMode,
    setIsShowSoilMap,
    setUserFieldPolygonModificationProperty,
    setSelectedUserFieldIdsInUserFieldList,
    setSelectedWaterGateIdsInWaterGateList,
    setUserFieldGroupIdList,
    setFilteredUserFieldGroupIdList,
    setUserFieldIdList,
    setFilteredUserFieldIdList,
    setWideOrthoimgUserFieldIds,
} = selectedStateSlice.actions

export const selectedStateReducer = selectedStateSlice.reducer
