import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LatLng } from './SelectedState'

export type BoundsChangeRequest = {
    latLngBounds: LatLng[],
}

export type MarkerShowRequest = {
    latLng: LatLng,
    zoom?: number,
} | null

export type Tooltip = {
    lat: number,
    lng: number,
    value: any,
}

export type GoogleMapState = {
    currentLatLng: number[][]
    currentZoomLevel: number
    boundsChangeRequest: BoundsChangeRequest
    markerShowRequest: MarkerShowRequest
    currentUserFieldsLatLngBounds: LatLng[]
    isShowCurrentLocation: boolean
    currentLocation: LatLng
    tooltip: Tooltip | null
}

export const defaultGoogleMapState: GoogleMapState = {
    currentLatLng: [
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
    ],
    currentZoomLevel: 6,
    boundsChangeRequest: {
        latLngBounds: [],
    },
    markerShowRequest: null,
    currentUserFieldsLatLngBounds: [],
    isShowCurrentLocation: false,
    currentLocation: { lat: 0, lng: 0 },
    tooltip: null,
}

const googleMapStateSlice = createSlice({
    name: "google-map-state",
    initialState: defaultGoogleMapState,
    reducers: {
        setCurrentLatLng: (state: GoogleMapState, action: PayloadAction<number[][]>) => {
            state.currentLatLng = action.payload
        },
        setCurrentZoomLevel: (state: GoogleMapState, action: PayloadAction<number>) => {
            state.currentZoomLevel = action.payload
        },
        setBoundsChangeRequest: (state: GoogleMapState, action: PayloadAction<BoundsChangeRequest>) => {
            state.boundsChangeRequest = action.payload
        },
        setMarkerShowRequest: (state: GoogleMapState, action: PayloadAction<MarkerShowRequest>) => {
            state.markerShowRequest = action.payload
        },
        setCurrentUserFieldsLatLngBounds: (state: GoogleMapState, action: PayloadAction<LatLng[]>) => {
            state.currentUserFieldsLatLngBounds = action.payload
        },
        setIsShowCurrentLocation: (state: GoogleMapState, action: PayloadAction<boolean>) => {
            state.isShowCurrentLocation = action.payload
        },
        setCurrentLocation: (state: GoogleMapState, action: PayloadAction<LatLng>) => {
            state.currentLocation = action.payload
        },
        setTooltip: (state: GoogleMapState, action: PayloadAction<Tooltip | null>) => {
            state.tooltip = action.payload
        },
    }
})

export const {
    setCurrentLatLng,
    setCurrentZoomLevel,
    setBoundsChangeRequest,
    setMarkerShowRequest,
    setCurrentUserFieldsLatLngBounds,
    setIsShowCurrentLocation,
    setCurrentLocation,
    setTooltip,
} = googleMapStateSlice.actions

export const googleMapStateReducer = googleMapStateSlice.reducer
