export const ERROR = {
    NETWORK: "[Network Error]",
    GRAPHQL: "[GraphQL Error]",
    FASTAPI: "[Fastapi Error]",
    UPLOAD: "[Upload Error]",
    INSERT: "[Insert Error]",
    UPDATE: "[Update Error]",
    DELETE: "[Delete Error]",
} as const

export const WARN = {
    INPUT: "[Invalid Input]",
    GEOLOCATION: "[Geolocation Failured]",
    LAYER: "[Layer display Failured]",
} as const

export const INFO = {
    SUCCESS: "[Success]",
    COMPLETE: "[Complete]",
} as const
