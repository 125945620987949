import { configureStore } from '@reduxjs/toolkit'
import { GoogleMapState, googleMapStateReducer } from '../stores/GoogleMapState'
import { UserState, userStateReducer } from '../stores/UserState'
import { LayerState, layerStateReducer } from '../stores/LayerState'
import { SelectedState, selectedStateReducer } from '../stores/SelectedState'
import { UiState, uiStateReducer } from './UiState'
import { CommonDialogState, commonDialogStateReducer } from './CommonDialogState'

/**
 * 最上位のState
 * Stateを新規追加した場合は、以下にも追加する
 */
export type RootState = {
    userState: UserState,
    uiState: UiState,
    googleMapState: GoogleMapState,
    selectedState: SelectedState,
    layerState: LayerState,
    commonDialogState: CommonDialogState,
}

/**
 * 最上位のReducer
 * Reducerを新規追加した場合は、以下にも追加する
 */
const rootReducer = {
    userState: userStateReducer,
    uiState: uiStateReducer,
    googleMapState: googleMapStateReducer,
    selectedState: selectedStateReducer,
    layerState: layerStateReducer,
    commonDialogState: commonDialogStateReducer,
}

// Storeの設定
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // serializable check対象外とした項目については、当該項目が正常に動作することを確認する必要あり
            // see: https://redux.js.org/faq/organizing-state#can-i-put-functions-promises-or-other-non-serializable-items-in-my-store-state
            // see: https://redux-toolkit.js.org/api/serializabilityMiddleware
            serializableCheck: {
                // 指定したaction typeをserializable check対象外とする
                ignoredActions: [
                    "common_dialog_state/openCommonDialog",
                    "google-map-state/setTooltip",
                ],
                // 指定したstate pathをserializable check対象外とする
                ignoredPaths: [
                    "commonDialogState.property.content",
                    "commonDialogState.property.onAccept",
                    "commonDialogState.property.onClosed",
                    "googleMapState.tooltip.value",
                ],
            },
        }),
})

export default store
