import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type CommonDialogProperty = {
    title?: string,
    content: JSX.Element | undefined,
    isFullWidth?: boolean,
    onAccept?: () => any,
    onClosed?: () => any,
}

export const defaultCommonDialogProperty: CommonDialogProperty = {
    title: "",
    content: undefined,
    isFullWidth: true,
    onAccept: undefined,
    onClosed: undefined,
} as const

export type CommonDialogState = {
    isOpen: boolean,
    property: CommonDialogProperty,
}

const defaultCommonDialogState: CommonDialogState = {
    isOpen: false,
    property: { ...defaultCommonDialogProperty },
} as const

const commonDialogStateSlice = createSlice({
    name: 'common_dialog_state',
    initialState: { ...defaultCommonDialogState },
    reducers: {
        openCommonDialog: (state: CommonDialogState, action: PayloadAction<CommonDialogProperty>) => {
            state.isOpen = true
            state.property = action.payload
        },
        closeCommonDialog: (state: CommonDialogState) => {
            state.isOpen = false
        },
    }
})

export const {
    openCommonDialog,
    closeCommonDialog,
} = commonDialogStateSlice.actions

export const commonDialogStateReducer = commonDialogStateSlice.reducer
