/* eslint-disable no-console */
import { v4 as uuid_v4 } from 'uuid'
import { useUser } from '@auth0/nextjs-auth0'
import packageJson from '../../package.json'
import * as Sentry from '@sentry/nextjs'

type InsertLogType = {
    variables: {
        id: string
        level: string
        app_name: string
        message: string
        detail: any
        userName: string | null
    }
}

export interface Logger {
    // error =======================================================
    //   エラーが利用に大きな影響を及ぼす可能性が高いエラー情報の出力
    //   ex: データ登録、更新、削除失敗etc
    error(message: string, detail?: any): void

    // info ========================================================
    //   処理が正常に行われた際の情報の出力
    //   ex: データ登録、更新、削除成功etc
    info(message: string, detail?: any): void

    // warn ========================================================
    //   不正値表示や不正値入力等の不正情報の出力
    warn(message: string, detail?: any): void

    // debug =======================================================
    //   開発の際の参考情報として出力する情報や
    //   エラーが利用に大きな影響を及ぼす可能性が低いエラー情報等の出力
    //   ex: トークン表示、キー表示、表示エラー、フォーム入力エラーetc
    debug(message: string, detail?: any): void
}

class CustomError extends Error {
    id: string;
    level: string;
    app_name: string;
    detail: any;

    constructor(message: string, id: string, level: string, app_name: string, detail: any) {
        super(message);
        this.id = id;
        this.level = level;
        this.app_name = app_name;
        this.detail = detail;
    }
}

// 製品状態ではconsoleを無効にする
const invalidateConsoleMessages = ():void=>{
    if (process.env.NEXT_PUBLIC_CONSOLE_LOG_ENABLED !== 'true') {
        if (typeof window !== 'undefined') {
            if (window.console) {
                // consoleの全てのメソッドを無効化 https://developer.mozilla.org/ja/docs/Web/API/console
                const methods = [
                    "log", "debug", "warn", "info", "error",
                    "assert","clear","count","countReset","dir",
                    "dirxml","group","groupCollapsed","groupEnd","profile",
                    "profileEnd","table","time","timeEnd","timeStamp", "trace"
                ];
                for(let i=0; i<methods.length; i++){
                    (window.console as any)[methods[i]] = function(){
                        // 空実装で上書きし、無効化します
                    };
                }
            }
        }
    }
}

export const getLogger = (user?: { name?: string | null | undefined }): Logger => {

    invalidateConsoleMessages()

    // sentryにエラー通知(next.config.jsでlocalからのsentry通知は無効化されている)
    const insertLog = (o: InsertLogType) => {
        const variables = o.variables
        const { id, level, app_name, message, detail } = variables
        const error = new CustomError(message,id,level,app_name,detail);
        Sentry.withScope((scope) => {
            scope.setExtra("id", error.id);
            scope.setExtra("level", error.level);
            scope.setExtra("app_name", error.app_name);
            scope.setExtra("detail", error.detail);
            Sentry.captureException(error);
        });

    }
    const postLog = (level: string, message: string, detail?: any) => {
        insertLog({
            variables: {
                id: uuid_v4(),
                level,
                app_name: packageJson.name,
                message,
                detail,
                userName: user ? user.name ?? null : null,
            },
        })
    }

    return {
        error(message: string, detail?: any) {
            postLog('error', message, detail)
            if (detail === undefined) {
                console.error(`error: ${message}`)
            } else {
                console.error(`error: ${message}`, {'detail': detail})
            }
            
        },

        info(message: string, detail?: any) {
            // postLog('info', message, detail) // errorだけ通知
            if (detail === undefined) {
                console.info(`info: ${message}`)
            } else {
                console.info(`info: ${message}`, {'detail': detail})
            }
        },

        warn(message: string, detail?: any) {
            // postLog('warn', message, detail) // errorだけ通知
            if (detail === undefined) {
                console.warn(`warn: ${message}`)
            } else {
                console.warn(`warn: ${message}`, {'detail': detail})
            }
        },

        debug(message: string, detail?: any) {
            // postLog('debug', message, detail) // errorだけ通知
            if (process.env.NODE_ENV === 'development') { // debugはローカルでだけ実行
                if (detail === undefined) {
                    console.debug(`debug: ${message}`)
                } else {
                    console.debug(`debug: ${message}`, {'detail': detail})
                }
            }
        },
    }
}

// リアクトコンポーネントから使う時はユーザ情報が追加される
export const useLogger = (): Logger => {
    const { user } = useUser()
    return(getLogger(user))
}
